//Обнуление
* {
	padding: 0;
	margin: 0;
	border: 0;
}
*,
*:before,
*:after {
	box-sizing: border-box;
}
:focus,
:active {
	outline: none;
}
a:focus,
a:active {
	outline: none;
}

nav,
footer,
header,
aside {
	display: block;
}

html,
body {
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
}
input,
button,
textarea {
	font-family: inherit;
}

input::-ms-clear {
	display: none;
}
button {
	cursor: pointer;
}
button::-moz-focus-inner {
	padding: 0;
	border: 0;
}
a,
a:visited {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}
ul li {
	list-style: none;
}
