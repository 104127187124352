@import "nullstyle";
@import "variables";
@import "mixins";

* {
	-webkit-tap-highlight-color: transparent;
}

body {
	background-color: $body-bg;
}

.container {
	max-width: 1060px;
	margin: 0 auto;
	padding: 0 10px;
	position: relative;
}

.text {
	font-family: 'PT Sans', sans-serif;
	font-size: 12px;
	font-weight: 400;
	color: $white-text;
	&_grey {
		color: $grey-text;
		font-weight: 700;
	}
	&_center {
		text-align: center;
	}
	&__h1 {
		font-size: 18px;
	}
	&__icon {
		vertical-align: middle;
	}
	&__profit {
		font-size: 22px;
		font-weight: 700;
		color: $green-text;
	}
	&__svg {
		height: auto;
		width: 15px;
	}
	&__header {
		&_p {
			font-size: 14px;
			margin-top: 20px;
		}
		&_title {
			margin-left: 30px;
		}
	}
}

.logo {
	display: block;
	&_center {
		margin: 0 auto;
	}
	&__header {
		height: auto;
		max-width: 100%;
		padding: 0 20px;
	}
	&__bonus {
		margin-left: 5px;
		transition: transform 0.2s ease-in-out;
	}
	&__choose {
		margin-left: 5px;
	}
	&__footer {
		margin: 0 10px;
	}
}

.buttonWithIcon {
	display: flex;
	align-items: center;
	background-color: $bar-color;
	& > p {
		margin-right: 5px;
	}
}

.hiddenSvg {
	display: none;
}

.header {
	&__background {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 50%;
		transform: translate(-50%);
	}
	&__logo {
		height: 150px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url(../img/background/ursa.jpg);
		background-repeat: no-repeat;
		background-position: center 0;
		background-size: auto auto;
	}
	&__wrapper {
		padding: 30px 0 30px;
		background-color: $footer-header;
		box-shadow: inset 0px 10px 10px -10px rgba(0, 0, 0, 1), inset 0px -10px 10px -10px rgba(0, 0, 0, 1);
	}
	&__intro {
		max-width: 980px;
		margin-left: 50px;
		margin-right: 30px;
	}
}

.main {
	padding: 30px 0 1px 0;
	background-image: url(../img/background/lina.jpg);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: auto auto;
	&__item {
		margin-bottom: 30px;
		&_withBonus {
			margin-bottom: 60px;
		}
	}
}

.bar {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 164px;
	min-height: 100px;
	background-color: $bar-color;
	box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
	color: $white-text;
	border-radius: 30px;
	position: relative;
	&:hover {
		box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.95);
	}
	&__item {
		position: relative;
		@include flexAndCenterItem(row);
		&:not(:nth-of-type(n + 5)):after {
			@include border();
		}
	}
	&__iconsGrid {
		display: flex;
		flex-wrap: wrap;
		width: 172px;
		max-height: 86px;
		overflow: hidden;
		& > img {
			margin: 10px;
		}
		&_4 {
			overflow: hidden;
			flex-wrap: nowrap;
		}
	}
	&__BetsIcons {
		display: flex;
		width: 105px;
		min-height: 43px;
		justify-content: space-around;
		align-items: center;
		& > img {
			margin: 0 10px;
		}
	}
	&__mobileText {
		display: none;
	}
	&__textWithIcons {
		display: flex;
		align-items: center;
		justify-content: center;
		align-self: center;
		height: 43px;
	}
	&__moreIcons {
		display: flex;
		align-self: center;
		flex-direction: column;
		min-width: 35px;
		height: 22px;
		padding-top: 3px;
		font-size: 10px;
		background-color: $bar-color;
		color: $white-text;
		align-items: center;
		border-radius: 10px;
		transition: all 0.2s ease;
		&:after {
			content: '...';
			font-size: 20px;
			line-height: 3px;
			font-weight: 700;
			margin-top: -5px;
		}
		&:hover {
			background-color: $btn-bg;
		}
		&_active {
			background-color: $btn-bg;
			border: none;
			border-radius: 10px;
			border-top-left-radius: 0;
			position: relative;
			padding-top: 5px;
			& > p {
				height: 100%;
			}
			&:after {
				content: '';
			}
		}
	}
	&__window {
		display: none;
		flex-wrap: wrap;
		padding: 20px;
		justify-content: start;
		width: 212px;
		height: auto;
		background-color: $bar-color;
		box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
		border-radius: 30px;
		position: absolute;
		z-index: 1;
		right: 70px;
		top: 50%;
		transform: translateY(-50%);
		& > img {
			margin: 10px;
		}
	}
	&__more {
		display: flex;
		align-items: center;
		position: absolute;
		bottom: 25px;
		right: 25px;
		& > p {
			margin-right: 5px;
		}
	}
}

.bartop {
	height: 100px;
	&__item {
		position: relative;
		@include flexAndCenterItem(column);
		height: 100px;
		&:not(:nth-of-type(5)):after {
			@include border();
		}
		&:nth-of-type(n + 3) {
			cursor: pointer;
		}
		&:hover .bartop__arrow_hidden {
			opacity: 1;
		}
		&_active {
			& > h1 {
				font-weight: 700;
			}
		}
	}
	&__text {
		margin: 5px 0;
		&_active {
			font-weight: 700;
		}
		&_bottom {
			color: $grey-text;
			font-size: 12px;
			font-weight: 700;
		}
		&_mobile {
			display: none;
			position: absolute;
			bottom: 10px;
		}
	}
	&__arrow {
		justify-self: center;
		&_hidden {
			opacity: 0;
		}
		&_active {
			filter: url(#white);
			animation: action 0.8s infinite alternate;
		}
		@keyframes action {
			0% {
				transform: translateY(0);
			}
			100% {
				transform: translateY(-5px);
			}
		}
	}
}

.bonus {
	display: block;
	margin: 0 auto !important;
	max-width: 88%;
	line-height: 30px;
	border-radius: 0;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	background-color: $bar-color;
	box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
	position: relative;
	&__content {
		margin: 0 auto;
		max-height: 0;
		overflow: hidden;
		transition: all 0.2s linear;
		&_active {
			max-height: none;
			height: auto;
			margin-bottom: 150px;
			border-radius: 30px;
		}
	}
	&__item {
		padding: 30px;
		position: relative;
		&:nth-child(1):after {
			@include borderBottom();
		}
		&:nth-child(2):after {
			@include borderBottom();
		}
	}
	&__title {
		padding: 10px;
		position: relative;
		&::after {
			@include borderBottom();
		}
	}
	&__text {
		line-height: normal;
		overflow-wrap: break-word;
	}
	&__p {
		margin-top: 25px;
		margin-left: 20px;
	}
	&__btn {
		display: flex;
		justify-content: center;
		width: 100%;
		max-height: 30px;
		background-color: $bar-color;
		color: $grey-text;
		cursor: pointer;
		box-shadow: inset 0px 20px 12px -22px rgba(0, 0, 0, 0.75);
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
		transition: all 0.2s linear;
		position: absolute;
		&:hover {
			padding-top: 10px;
			max-height: 40px;
		}
		&__active {
			box-shadow: none;
			max-height: 40px;
			padding-top: 10px;
			&:hover {
				max-height: 40px;
			}
			& .logo__bonus {
				transform: rotate(180deg);
			}
		}
	}
	&__btnText {
		display: inline-block;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	&__overlay {
		border-radius: 30px;
	}
}

.footer {
	padding: 20px 20px;
	background-color: $footer-header;
	box-shadow: inset 0px 10px 10px -10px rgba(0, 0, 0, 1);
	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__text {
		max-width: 80%;
		margin: 20px 0;
	}
	&__contacts {
		display: flex;
		margin-top: 10px;
		justify-content: center;
	}
	&__p {
		font-size: 14px;
	}
}

/* popup */

.overlay {
	height: 0;
	width: 100%;
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	background-color: rgba(37, 37, 43, 0.8);
	overflow-x: hidden;
}

.detailedBar {
	display: block;
	height: auto;
	margin: 150px 0 30px;
	padding: 50px;
	border-radius: 30px;
	color: $white-text;
	background-color: $bar-color;
	box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
	position: relative;
	&__closeButton {
		position: absolute;
		top: 25px;
		right: 25px;
		z-index: 3;
	}
	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		min-height: 120px;
		width: 100%;
		padding-bottom: 15px;
		position: relative;
		&:not(:last-child):after {
			@include borderBottom();
		}
		&:nth-child(4) {
			margin-bottom: 100px;
		}
	}
	&__itemHalf {
		display: flex;
		flex-wrap: wrap;
		width: 50%;
		justify-content: center;
		align-items: center;
		position: relative;
		&:nth-child(1)::after {
			@include border();
		}
	}
	&__profit {
		display: flex;
		align-items: center;
		justify-content: center;
		& > h1 {
			margin-right: 10px;
		}
	}
	&__description {
		width: 100%;
		position: relative;
		margin-top: auto;
		&_right {
			width: 45%;
			margin-left: auto;
		}
	}
	&__infoIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 12px;
		height: 12px;
		border-radius: 6px;
		background-color: $grey-text;
		cursor: pointer;
		position: absolute;
		right: -30px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 3;
		& > p {
			color: $bar-color;
			font-weight: 700;
			font-size: 10px;
		}
		&_active {
			border-top-left-radius: 0;
		}
	}
	&__window {
		display: none;
		margin: 0;
		min-width: 300px;
		width: 300px;
		height: auto;
		padding: 20px;
		position: absolute;
		right: 30px;
		z-index: 4;
	}
	&__disciplines {
		margin-top: 40px;
	}
	&__absoluteTitle {
		position: absolute;
		top: 30px;
		left: 0;
		&_bottom {
			top: -70px;
		}
	}
	&__icons {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		height: fit-content;
		width: 100%;
		margin-bottom: 15px;
		& > img {
			margin: 5px 10px;
		}
	}
	&__text {
		color: $grey-text;
		text-align: center;
		padding-top: 20px;
	}
	&__betsTypes {
		min-width: 500px;
		max-width: 540px;
		margin-top: 15px;
		& > div {
			margin-top: 10px;
		}
		&_upper {
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
		}
		&_lower {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
		}
	}
	&__bet {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		& > img {
			margin-right: 5px;
		}
	}
	&__ways {
		display: flex;
		width: 100%;
		justify-content: space-around;
		&_input,
		&_output {
			width: 230px;
			min-width: 100px;
		}
		&:after {
			@include border(70%);
			right: 50%;
		}
	}
	&__textWithIcons {
		display: flex;
		align-items: center;
		&:first-child {
			overflow: hidden;
			margin: 0;
			& > p {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	&__tableField {
		display: flex;
		align-items: center;
		height: 23px;
		margin-top: 20px;
		& > img {
			margin-right: 10px;
		}
		&_disabled {
			& > img,
			& > p,
			& > div {
				filter: url(#white) url(#disabled);
			}
		}
		&_first {
			justify-content: center;
		}
	}
	&__column {
		&_second {
			flex: 1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		&_third {
			flex: 0 0 100px;
			display: flex;
			justify-content: center;
			& .text {
				min-width: 17px;
				display: flex;
				justify-content: center;
			}
		}
	}
}

.afterField {
	font-family: 'PT Sans', sans-serif;
	font-size: 11px;
	font-weight: 700;
	text-align: center;
	color: $bar-color;
	min-width: 20px;
	height: 12px;
	padding: 1px 6px 0 6px;
	margin-left: 10px;
	border-radius: 6px;
	&_green {
		background-color: $green-text;
	}
	&_red {
		background-color: #d10b1d;
	}
	&_yellow {
		background-color: #f2ac06;
	}
}

/* Responsive */

@media screen and (max-width: 1500px) {
	.main {
		background: none;
	}
}

@media screen and (max-width: 992px) {
	.container {
		max-width: 960px;
	}
	.bar,
	.bartop {
		&__item {
			width: 240px;
			&:nth-child(2) {
				display: none;
			}
		}
	}
	.footer__content {
		flex-wrap: wrap-reverse;
		justify-content: center;
	}
	.bonus__btn {
		&:hover {
			padding-top: 0;
			max-height: none;
		}
		&__active {
			max-height: 30px;
			padding-top: 0;
			&:hover {
				max-height: none;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.container {
		max-width: 720px;
	}
	.bar {
		height: auto;
		padding: 20px;
		flex-wrap: wrap;
		justify-content: space-around;
		&__mobileText {
			display: block;
			text-align: start;
			margin-bottom: 0px;
		}
		&__iconsGrid {
			flex-wrap: nowrap;
			justify-content: flex-start;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				background: linear-gradient(90deg, rgba(17, 17, 20, 0) 0%, rgba(17, 17, 20, 1) 100%);
				width: 20px;
				height: 43px;
			}
			&:nth-child(1) {
				width: auto;
			}
			&_4 {
				width: auto;
				max-width: 100%;
				position: relative;
			}
		}
		&__more {
			top: 15px;
			right: 20px;
			bottom: auto;
			background-origin: padding-box;
			& > p {
				display: none;
			}
		}
		&__moreIcons {
			display: none;
		}
	}
	.bar__item {
		height: auto;
		&:nth-child(n) {
			&:after {
				content: none;
			}
		}
		&:nth-child(1) {
			order: 1;
			width: 100%;
		}
		&:nth-child(5) {
			order: 2;
			width: 100%;
			width: 260px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		&:nth-child(2) {
			order: 3;
			display: flex;
			width: 100%;
			padding: 20px 0;
		}
		&:nth-child(3) {
			order: 4;
			display: block;
			width: 260px;
			.bar {
				&__bets {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					height: 86px;
				}
			}
		}
		&:nth-child(4) {
			display: block;
			order: 5;
			width: 260px;
			.bar__bets {
				display: flex;
				flex-direction: column-reverse;
				align-items: center;
			}
		}
	}
	.bartop {
		height: 100px;
		padding: 0;
		overflow: hidden;
		&__item {
			height: 100px;
			width: 100%;
			order: 2;
			&:nth-child(n) {
				&:after {
					content: none;
				}
			}
			&:nth-of-type(1) {
				display: none;
			}
			&_active {
				order: 1;
				display: flex;
			}
		}
		&__text_mobile {
			display: flex;
		}
	}
	.bonus {
		max-width: 80%;
	}
	.detailedBar {
		&__item:not(:last-child) {
			flex-direction: column;
			align-items: center;
		}
		&__item:nth-child(4) {
			margin-bottom: 70px;
		}
		&__itemHalf {
			width: 100%;
			&:nth-child(n) {
				&:after {
					content: none;
				}
			}
		}
		&__profit {
			flex-wrap: wrap;
		}
		&__description {
			width: 95%;
			&_right {
				margin: 15px auto 0;
			}
		}
		&__infoIcon {
			right: -15px;
		}
		&__absoluteTitle {
			top: 20px;
			&_bottom {
				top: -50px;
			}
		}
		&__betsTypes {
			min-width: auto;
			margin-top: 35px;
			&_lower {
				flex-direction: column;
				align-items: center;
				& > div {
					margin-bottom: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	body {
		min-width: 320px;
	}
	.container {
		max-width: 540px;
		& > .bonus {
			max-width: 100%;
		}
	}
	.bar {
		&__item {
			margin-bottom: 15px;
			&:nth-child(2) {
				padding: 0 0 15px;
				position: relative;
				&:after {
					@include borderBottom(100px);
					top: 100%;
				}
			}
			&:nth-child(3) {
				.bar__bets {
					height: auto;
				}
			}
			&:nth-child(4) {
				margin-bottom: 0;
				width: 100%;
				& > p {
					width: 260px;
					margin: 0 auto;
				}
			}
		}
	}
	.detailedBar__ways_output .detailedBar__tableField {
		justify-content: flex-end;
		&:not(:first-child) img,
		&:not(:first-child) .detailedBar__column_second {
			display: none;
		}
	}
	.detailedBar {
		&__tableField {
			&_first {
				justify-content: space-between;
			}
			&_disabled {
				& > img,
				& > p {
					filter: none;
				}
			}
		}
		&__ways {
			justify-content: center;
			&_output {
				width: auto;
				margin-left: 15px;
				& .detailedBar__tableField_first .detailedBar__textWithIcons:first-child {
					display: none;
				}
			}
			&::after {
				content: none;
			}
		}
		&__profit > h1,
		&__profit .afterField {
			margin-top: 10px;
		}
		&__textWithIcons {
			flex: 0 0 100px;
			margin-left: 10px;
			&:first-child {
				flex: 1;
			}
		}
	}
	.bonus {
		&__content {
			display: none;
		}
		&__btn {
			max-height: 30px;
			& .logo__bonus {
				display: none;
			}
			&:after {
				content: '...';
				font-size: 33px;
				display: flex;
				letter-spacing: -3px;
				margin-left: 2px;
				align-self: center;
				padding-bottom: 18px;
			}
		}
	}
	.main__item_withBonus {
		& > .bonus__content {
			display: none;
		}
	}
}
